.app-header__logo .logo-src {
    height: 50px;
    width: unset;
}

.table-action-elem {
    margin: 3px;
}

.pagination-parent {
    display: flex;
}

.pagination {
    margin: auto !important;
}

.clickable-row {
    cursor: pointer;
}

.fixed-footer .app-footer {
    z-index: 1001;
}

.messaging-options {
    width: 500px;
    margin: auto;
}

.textarea-input {
    height: 100px;
}

textarea {
    overflow: auto !important;
}

.seen {
    background: #f0f8ffd6;
}

.not-seen {
    font-weight: bold;
    background: white;
}

.btn-search {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 100%;
    font-size: 30px;
    color: var(--primary);
    background: transparent;
    padding: 2px;
    border-color: transparent;
}

.btn-search:hover,
.btn-search.focus,
.btn-search:focus {
    background: transparent !important;
    border-color: transparent !important;
    color: var(--primary) !important;
}
